import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, Container, Stack, MenuItem, InputLabel, FormControl, Select, Paper, Grid, InputAdornment, Divider, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import axios from 'axios';
import PaymentIcon from '@mui/icons-material/Payment';
import { CalendarMonth } from '@mui/icons-material';

import {
    CitySelect,
    CountrySelect,
    StateSelect,
    LanguageSelect,
    GetCountries,
    GetCity,
    GetState,
} from 'react-country-state-city';
import 'react-country-state-city/dist/react-country-state-city.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import QrCodeIcon from '@mui/icons-material/QrCode';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { ToWords } from 'to-words';
import { Autocomplete, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search'; // Importing the SearchIcon
import withAuth from '../../HighOrderComponent/withAuth';
import apiUrl from '../../Api/api';


const toWords = new ToWords({
    localeCode: 'en-IN', // You can change locale if needed
    converterOptions: {
        currency: false,
        ignoreDecimal: true,
    },
});

const ReceiptExistingDonor = ({ error, handleLoading, handleError, onDownloadReceipt }) => {

    const [countryid, setCountryid] = useState('');
    const [stateid, setStateid] = useState('');
    const [cityid, setCityid] = useState('');

    const [countriesList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [isEditable, setIsEditable] = useState(false);

    const [formData, setformData] = useState({
        donor_category: '',
        rep_id: '0',
        prefix: '',
        name: '',
        isd_code: '91',
        mobile: '',
        email: '',
        address1: '',
        address2: '',
        city_name: '',
        state_name: '',
        country_name: '',
        pincode: '',
        pan: '',
        admin_id: '1',
        status: 'Y', // Default status can be 'A' for Active
        amountinNumbers: '',
        amountinWords: '',
        paymentMode: '',
        paymentDate: '',
        supportOption: '',
        otherPurposes: '',
        chequeNumber: '',
        chequeDate: '',
        bankName: '',
        bankBranch: '',
        refNumber: '',
        govedaBank: '',
        unique_Id: "",
        type: "Existing Donor"
    });

    // payment mode
    const handlePaymentModeClick = (mode) => {
        setformData({ ...formData, paymentMode: mode });
    };

    // amount onchange
    const handleAmount = (e) => {
        const { name, value } = e.target;

        if (name === 'amountinNumbers') {
            const amountInWords = toWords.convert(value || 0); // Convert number to words
            setformData({
                ...formData,
                amountinNumbers: value,
                amountinWords: amountInWords, // Set the converted value to the amountinWords field
            });
        } else {
            setformData({ ...formData, [name]: value });
        }
    };

    // textfields onchange
    const handleChange = (e) => {
        const { name, value } = e.target;
        setformData((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const [selectedOption, setSelectedOption] = useState(formData.donor_category);
    const [dropdownOptions, setDropdownOptions] = useState([]);

    const handleRadioChange = (event) => {

        const { name, value } = event.target;
        setSelectedOption(value);
        console.log(value);
        setformData((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));

        // Update dropdown options based on selected radio button
        if (value === 'Individual') {
            setDropdownOptions(['Shri', 'Smt', 'Kumari']);
        } else if (value === 'Institution') {
            setDropdownOptions(['M/s']);
        } else if (value === 'Representative') {
            setDropdownOptions(['Shri', 'Smt', 'Kumari']);
        }
    };

    useEffect(() => {
        setSelectedOption(formData.donor_category);
        if (formData.donor_category === 'Individual') {
            setDropdownOptions(['Shri', 'Smt', 'Kumari']);
        } else if (formData.donor_category === 'Institution') {
            setDropdownOptions(['M/s']);
        } else if (formData.donor_category === 'Representative') {
            setDropdownOptions(['Shri']);
        }

    }, [formData.donor_category, formData.prefix,]);

    useEffect(() => {
        GetCountries().then((result) => {
            setCountriesList(result);
        });
    }, []);

    // handle pre-defined database values
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleInputChange = async (event, value) => {
        setInputValue(value);

        if (value) {
            setLoading(true);
            try {
                const response = await axios.get(`${apiUrl}/admin/existing-donor-search`, {
                    params: { query: value }
                });
                setSuggestions(response.data);

            } catch (error) {
                console.error('Error fetching suggestions:', error);
            } finally {
                setLoading(false);
            }
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionSelect = async (event, selectedOption) => {
        if (selectedOption) {
            // Update formData with selected option
            setformData((prevData) => ({
                ...prevData,
                donor_category: selectedOption.donor_category,
                prefix: selectedOption.prefix,
                name: selectedOption.name,
                isd_code: selectedOption.isd_code,
                mobile: selectedOption.mobile,
                email: selectedOption.email,
                address1: selectedOption.address1,
                address2: selectedOption.address2,
                pincode: selectedOption.pincode,
                pan: selectedOption.pan,
                admin_id: selectedOption.admin_id,
                status: selectedOption.status,
                unique_Id: selectedOption.uniqueId,
            }));

            try {
                // Fetch all countries
                const countries = await GetCountries();
                const country = countries.find(c => c.name.toLowerCase() === selectedOption.country_name.toLowerCase());

                if (country) {
                    setCountryid(country.id);
                    setformData(prev => ({ ...prev, country_name: country.name }));

                    // Fetch states based on the found country
                    const states = await GetState(country.id);
                    const state = states.find(s => s.name.toLowerCase() === selectedOption.state_name.toLowerCase());

                    GetState(country.id).then((result) => {
                        setStateList(result);
                    });

                    if (state) {
                        setStateid(state.id);
                        setformData(prev => ({ ...prev, state_name: state.name }));

                        // Fetch cities based on the found state
                        const cities = await GetCity(country.id, state.id);
                        const city = cities.find(c => c.name.toLowerCase() === selectedOption.city_name.toLowerCase());

                        GetCity(country.id, state.id).then((result) => {
                            setCityList(result);
                        });

                        if (city) {
                            setCityid(city.id);
                            setformData(prev => ({ ...prev, city_name: city.name }));
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        }
    };


    const handleCountryChange = (e) => {

        const selectedCountry = countriesList.find(country => country.id === e.target.value);
        console.log(selectedCountry);
        if (selectedCountry) {
            setCountryid(selectedCountry.id);
            setStateid(''); // Reset state and city when changing country
            setCityid('');
            GetState(selectedCountry.id).then((result) => {
                setStateList(result);
            });
        }
        setformData(prev => ({ ...prev, country_name: selectedCountry.name }));

    };

    const handleStateChange = (e) => {
        const selectedState = stateList.find(state => state.id === e.target.value);
        if (selectedState) {
            setStateid(selectedState.id);
            setCityid(''); // Reset city when changing state
            GetCity(countryid, selectedState.id).then((result) => {
                setCityList(result);
            });
        }
        setformData(prev => ({ ...prev, state_name: selectedState.name }));

    };

    const handleCityChange = (e) => {
        const selectedCity = cityList.find(city => city.id === e.target.value);
        if (selectedCity) {
            setCityid(selectedCity.id);
        }
        setformData(prev => ({ ...prev, city_name: selectedCity.name }));

    };


    // submit and downlaod
    const downloadReceipt = async (e) => {

        console.log(formData);
        onDownloadReceipt(formData);
        e.preventDefault();
        setformData({
            amountinNumbers: '',
            amountinWords: '',
            paymentMode: '',
            paymentDate: '',
            supportOption: '',
            chequeNumber: '',
            chequeDate: '',
            bankName: '',
            bankBranch: '',
            refNumber: '',
            govedaBank: '',
            type: "Existing Donor"
        });

    };



    return (

        <Box sx={{ mt: 2 }}>
            <Autocomplete
                sx={{
                    maxWidth: 600,
                    mb: 2,
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
                    },
                }}
                freeSolo
                options={suggestions}
                getOptionLabel={(option) => {
                    const name = option.name || "No Name";
                    const email = option.email || "No Email";
                    const mobile = option.mobile || "No Mobile";
                    return `${name} | ${email} | ${mobile}`;
                }}
                onInputChange={handleInputChange}
                onChange={handleSuggestionSelect}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Search by Name, Email, or Mobile"
                        variant="outlined"
                        placeholder="Search by Name, Email, or Mobile..."
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="action" /> {/* Search Icon */}
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        sx={{
                            '& .MuiInputLabel-root': {
                                color: '#666', // Change label color
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#ccc', // Default border color
                                },
                            },
                        }}
                    />
                )}
            />

            <form onSubmit={downloadReceipt} style={{ marginTop: "40px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ p: 2, display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row" }}>
                        <Typography variant='h5'>Donor Details</Typography>
                        <Button variant='contained' size='large' sx={{ textAlign: "end" }} onClick={() => setIsEditable(!isEditable)}>Edit</Button>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl component="fieldset" fullWidth disabled={!isEditable}>
                            <RadioGroup
                                row
                                aria-label="user-type"
                                name="donor_category"
                                value={selectedOption}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value="Individual" control={<Radio />} label="Individual" />
                                <FormControlLabel value="Institution" control={<Radio />} label="Institution" />
                                <FormControlLabel value="Representative" control={<Radio />} label="Representative" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center" gap={5} width="100%">
                            <FormControl variant="outlined" sx={{ minWidth: 120, maxWidth: 100 }} disabled={!isEditable}>
                                <InputLabel>Prefix</InputLabel>
                                <Select
                                    value={formData.prefix}
                                    onChange={handleChange}
                                    name="prefix"
                                    label="Prefix"
                                >
                                    {dropdownOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                fullWidth
                                label="Name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                disabled={!isEditable}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            type='email'
                            value={formData.email}
                            onChange={handleChange}
                            disabled={!isEditable}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center" width="100%">
                            {/* ISD Code Input */}
                            <PhoneInput
                                country={'in'}
                                value={formData.isd_code}
                                onChange={(value) => {
                                    setformData({
                                        ...formData,
                                        isd_code: value, // Store ISD code
                                    });
                                    console.log(value); // Log the phone number including the ISD code
                                }}
                                inputProps={{
                                    maxLength: 8, // Limit the length of the ISD code
                                }}
                                disabled={!isEditable}
                            />

                            {/* Mobile Number Input */}
                            <TextField
                                fullWidth
                                label="Mobile"
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleChange}
                                required
                                inputProps={{
                                    maxLength: 10, // Limit the length of the ISD code
                                }}
                                disabled={!isEditable}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}> {/* Adjust the size for responsiveness */}
                                <TextField
                                    fullWidth
                                    label="Address 1"
                                    name="address1"
                                    value={formData.address1}
                                    onChange={handleChange}
                                    required
                                    disabled={!isEditable}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}> {/* Adjust the size for responsiveness */}
                                <TextField
                                    fullWidth
                                    label="Address 2"
                                    name="address2"
                                    value={formData.address2}
                                    onChange={handleChange}
                                    disabled={!isEditable}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Pincode"
                            name="pincode"
                            value={formData.pincode}
                            onChange={handleChange}
                            required
                            disabled={!isEditable}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="PAN"
                            name="pan"
                            value={formData.pan}
                            onChange={handleChange}
                            disabled={!isEditable}
                        />
                    </Grid>



                    {/* Country, State, City Selects */}
                    <Grid item xs={12} sm={6} >

                        <FormControl fullWidth variant="outlined" disabled={!isEditable}>
                            <InputLabel>Country</InputLabel>
                            <Select
                                value={countryid}
                                onChange={handleCountryChange}
                                label="Country"
                            >
                                {countriesList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined" disabled={!isEditable}>
                            <InputLabel>State</InputLabel>
                            <Select
                                value={stateid}
                                onChange={handleStateChange}
                                label="State"
                            >
                                {stateList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>

                        <FormControl fullWidth variant="outlined" disabled={!isEditable}>
                            <InputLabel>City</InputLabel>
                            <Select
                                value={cityid}
                                onChange={handleCityChange}
                                label="City"
                            >
                                {cityList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>
                <br />
                <Divider />

                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12} sx={{ p: 2 }}>
                        <Typography variant='h5'>Donation Details</Typography>
                    </Grid>
                    {/* purpose */}
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Support Options</InputLabel>
                            <Select
                                label="Support Options"
                                name="supportOption"
                                value={formData.supportOption}
                                onChange={handleChange}
                                sx={{
                                    '&:focus': {
                                        borderColor: '#38a1f7', // Change border color on focus
                                    },
                                }}
                            >
                                <MenuItem value="General Fund">General Fund</MenuItem>
                                <MenuItem value="Corpus Fund">Corpus Fund</MenuItem>
                                <MenuItem value="Supporting Patashala">Supporting Patashala</MenuItem>
                                <MenuItem value="Samaradanai">Samaradanai</MenuItem>
                                <MenuItem value="Annadanam">Annadanam</MenuItem>
                                <MenuItem value="Village Parayanam">Village Parayanam</MenuItem>
                                <MenuItem value="Others">Others</MenuItem>
                            </Select>
                        </FormControl>

                        {/* other purposes */}
                        {formData.supportOption === 'Others' && (
                            <TextField
                                fullWidth
                                variant="standard"
                                label="Please specify"
                                name="otherPurposes"
                                value={formData.otherPurposes || ''}
                                onChange={handleChange}
                                required={formData.supportOption === 'Others'}
                                sx={{
                                    marginTop: 2, // Add space between the Select and TextField
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#38a1f7', // Change border color on focus
                                        },
                                    },
                                }}
                            />
                        )}
                    </Grid>

                    {/* amount in numbers */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Amount in Numbers"
                            name="amountinNumbers"
                            value={formData.amountinNumbers}
                            onChange={handleAmount}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        Rs.
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            type='number'
                            sx={{ bgcolor: 'white' }}
                            required
                        />
                    </Grid>
                    {/* payment mode */}
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" align="center" gutterBottom sx={{ p: 2 }}>
                            Choose Payment Mode
                        </Typography>

                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    startIcon={<AttachMoneyIcon />}
                                    variant={formData.paymentMode === 'CASH' ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={() => handlePaymentModeClick('CASH')}
                                >
                                    CASH
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    startIcon={<QrCodeIcon />}
                                    variant={formData.paymentMode === 'UPI' ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={() => handlePaymentModeClick('UPI')}
                                >
                                    UPI
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    startIcon={<PaymentIcon />}
                                    variant={formData.paymentMode === 'Cheque' ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={() => handlePaymentModeClick('Cheque')}
                                >
                                    Cheque
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    startIcon={<AccountBalanceIcon />}
                                    variant={formData.paymentMode === 'Bank Transfer' ? 'contained' : 'outlined'}
                                    color="primary"
                                    onClick={() => handlePaymentModeClick('Bank Transfer')}
                                >
                                    Bank Transfer
                                </Button>
                            </Grid>

                            <Box sx={{ mt: 2 }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Reference Number
                                </Typography>
                                <TextField
                                    label="Reference Number"
                                    name="refNumber"
                                    value={formData.refNumber}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    placeholder="Enter reference number"
                                />
                            </Box>


                            {/* Conditional rendering for cheque details */}
                            {formData.paymentMode === 'Cheque' && (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Cheque Details
                                    </Typography>
                                    <TextField
                                        label="Cheque Number"
                                        name="chequeNumber"
                                        value={formData.chequeNumber}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="Enter cheque number"
                                    />
                                    <TextField
                                        label="Cheque Date"
                                        name="chequeDate"
                                        type="date"
                                        value={formData.chequeDate}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <Typography variant='caption' color='textSecondary'> credited to :</Typography>
                                    <TextField
                                        label="Goveda Bank"
                                        name="govedaBank"
                                        value={formData.govedaBank}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="Enter Goveda Bank name"
                                    />
                                </Box>
                            )}

                            {/* Conditional rendering for bank transfer details */}
                            {formData.paymentMode === 'Bank Transfer' && (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Bank Transfer Details
                                    </Typography>
                                    <TextField
                                        label="Bank Name"
                                        name="bankName"
                                        value={formData.bankName}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="Enter bank name"
                                    />
                                    <TextField
                                        label="Bank Branch"
                                        name="bankBranch"
                                        value={formData.bankBranch}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="Enter branch name"
                                    />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                    {/* payment date */}
                    <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
                        <TextField
                            name="paymentDate"
                            label="Payment Date"
                            type="date"
                            value={formData.paymentDate}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CalendarMonth />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ bgcolor: 'white' }}
                        />
                    </Grid>


                    {/* Submit Button */}
                    <Box sx={{ width: "100%", textAlign: "center", mt: 5 }}>
                        <Button variant="contained" color="primary" type="submit" size='large' sx={{ mt: 2, maxWidth: 400, p: 2 }} disabled={formData.paymentMode === ""}>
                            Submit
                        </Button>
                    </Box>

                </Grid>
            </form>

        </Box>
    )
}

export default withAuth(ReceiptExistingDonor);