import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Container, Stack, MenuItem, InputLabel, FormControl, Select, Paper, Grid, InputAdornment, Divider } from '@mui/material';
import axios from 'axios';
import AdminHeader from '../AdminHeader';
import apiUrl from '../../Api/api';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {
    Snackbar,
    Alert,
} from '@mui/material';
import { ToWords } from 'to-words';
import ReceiptNewDonor from './ReceiptNewDonor';
import ReceiptExistingDonor from './ReceiptExistingDonor';
import withAuth from '../../HighOrderComponent/withAuth';


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
};

const toWords = new ToWords({
    localeCode: 'en-IN', // You can change locale if needed
    converterOptions: {
        currency: false,
        ignoreDecimal: true,
    },
});


const AdminReceiptCreator = ({ loading, error, handleLoading, handleError }) => {

    const [value, setValue] = useState(3);
    const [formData, setformData] = useState({}); // Initialize state for formData
    const [receiptId, setreceiptId] = useState(null);
    // snack bar
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // can be 'success' or 'error'

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const handleTabs = (event, newValue) => {
        setValue(newValue);
    };


    const receiptRef = React.useRef();

    // submit and downlaod
    const downloadReceipt = async (formData) => {

        setformData(formData);
        handleLoading(true)

        if (formData.type === "Existing Donor" && (!formData.unique_Id || formData.unique_Id === "")) {
            return (
                <Snackbar
                    open={true}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
                        Kindly select the existing user before updating.
                        If new donor? Please add the donor in the previous tab.
                    </Alert>
                </Snackbar>
            );
        }



        try {
            const response = await axios.post(`${apiUrl}/admin/${formData.type === "New Donor" ? "add-new-recipient" : "existing-recipient"} `, formData);

            const newReceiptId = response.data.receiptId;
            setreceiptId(newReceiptId);

            setSnackbarSeverity("success")
            setSnackbarMessage(response.data.message);
            setOpenSnackbar(true);

            setTimeout(() => {
                // download pdf
                html2canvas(receiptRef.current, { scale: 2 })
                    .then((canvas) => {
                        const imgData = canvas.toDataURL('image/png');
                        const pdf = new jsPDF({
                            orientation: 'portrait',
                            unit: 'mm',
                            format: 'a4'
                        });

                        // A4 dimensions in mm
                        const a4Width = 210; // mm
                        const a4Height = 297; // mm
                        const margin = 5; // 10mm margin

                        const imgWidth = a4Width - margin * 2; // Adjust for left and right margins
                        const imgHeight = (canvas.height * imgWidth) / canvas.width;

                        // Add the image to the PDF
                        let positionY = margin; // Starting position from top

                        pdf.addImage(imgData, 'PNG', margin, positionY, imgWidth, imgHeight);

                        // Check if the image height exceeds A4 page height
                        let heightLeft = imgHeight - (a4Height - margin * 2);
                        while (heightLeft >= 0) {
                            pdf.addPage();
                            positionY = heightLeft - imgHeight + margin; // Maintain top margin on new pages
                            pdf.addImage(imgData, 'PNG', margin, positionY, imgWidth, imgHeight);
                            heightLeft -= a4Height;
                        }

                        // to download locally
                        // pdf.save('govedareceipt.pdf'); // Save the PDF

                        // Call the function to send PDF to backend
                        sendPdfToBackend(pdf, formData.email, formData.name, formData.mobile, newReceiptId);
                        // Reset the form
                        setformData({
                            amountinNumbers: '',
                            amountinWords: '',
                            paymentMode: '',
                            paymentDate: '',
                            supportOption: '',
                            chequeNumber: '',
                            chequeDate: '',
                            bankName: '',
                            bankBranch: '',
                            refNumber: '',
                        });
                    });
                handleLoading(false)
            }, 2000); // Delay of 2 seconds (2000 milliseconds)


        } catch (error) {
            handleError(error.response.data.message || error.response.data.error || "An error occured")
            setSnackbarMessage(error.response.data.message || error.response.data.error || "An error occurred");
            setSnackbarSeverity('error'); // Error message
            setOpenSnackbar(true);
            console.error('Error adding donor:', error);
        }
    };


    const sendPdfToBackend = (pdf, email, name, mobile, receiptId) => {

        console.log(receiptId);
        // Convert the PDF to Base64
        const pdfBase64 = pdf.output('datauristring').split(',')[1];

        // Send the Base64 PDF to backend
        fetch(`${apiUrl}/admin/send-pdf-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ pdfBase64, email, name, mobile, receiptId }),
        })
            .then(response => response.json())
            .then(() => {
                window.location.reload();
            })
            .catch(error => console.error('Error sending PDF:', error));
    };



    return (

        <Container maxWidth="lg">
            <AdminHeader />
            <Container maxWidth="lg" sx={{ mb: 10 }}>


                {/* tabs */}
                <Box sx={{ mt: { xs: 5, md: 15 } }} gap={5}>
                    <Box
                        sx={{
                            maxWidth: 400,
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            mb: 2, // Margin below the buttons
                        }}
                    >
                        <Button
                            variant={value === 0 ? 'contained' : 'outlined'}
                            color="primary"
                            size='large'
                            onClick={() => handleTabs({}, 0)} // Call the same handle function with the appropriate index
                            sx={{
                                minWidth: 120,
                                border: '1px solid',
                                borderColor: value === 0 ? 'primary.main' : '', // Active button color
                                bgcolor: value === 0 ? '#135e9b' : 'white', // Active background
                                '&:hover': {
                                    bgcolor: value === 0 ? 'primary.dark' : 'grey.200', // Hover effect
                                },
                            }}
                        >
                            New
                        </Button>
                        <Button
                            variant={value === 1 ? 'contained' : 'outlined'}
                            color="primary"
                            size='large'
                            onClick={() => handleTabs({}, 1)} // Call the same handle function with the appropriate index
                            sx={{
                                minWidth: 120,
                                border: '1px solid',
                                borderColor: value === 1 ? 'primary.main' : '', // Active button color
                                bgcolor: value === 1 ? '#135e9b' : 'white', // Active background
                                '&:hover': {
                                    bgcolor: value === 1 ? 'primary.dark' : 'grey.200', // Hover effect
                                },
                            }}
                        >
                            Existing
                        </Button>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <ReceiptNewDonor onDownloadReceipt={downloadReceipt} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ReceiptExistingDonor onDownloadReceipt={downloadReceipt} />
                    </TabPanel>

                </Box>

                {loading && <Typography variant='h5' gutterBottom textAlign="center" color='textSecondary'>Processing the receipt. Kindly wait...</Typography>}

                {/* Snackbar component */}
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>

            </Container>


            {/* preview */}
            {
                value !== 3 ?
                    (
                        < Container >
                            <Typography>Pdf Preview :</Typography>
                            <Box sx={{ p: 3, maxWidth: 'lg', margin: '0 auto' }} >
                                <Paper
                                    elevation={3}
                                    sx={{
                                        padding: 1.5,
                                        border: "2px solid black",
                                        m: 2,
                                        width: '210mm', // Fixed width for A4
                                        height: '297mm', // Fixed height for A4
                                        boxSizing: 'border-box', // Include padding and border in the element's total width and height
                                    }}
                                    ref={receiptRef}
                                >
                                    <Grid container alignItems="center" sx={{ borderBottom: "1px solid grey", p: 1 }}>
                                        <Grid item xs={6}>
                                            <Box>
                                                <img src={require("../../images/goveda-logo.png")} alt="GoVeda Logo" height={50} />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Box textAlign="right">
                                                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                                                    GoVeda Foundation
                                                </Typography>
                                                <Typography variant="subtitle2" sx={{ fontSize: '0.6rem', color: "black" }}>
                                                    Functional Office: Shri Kamakoti Veda Shastra Vidyalaya, <br />
                                                    Plot no.1, Nagammal Ave Rd, Narayanapuram,<br />
                                                    Pallikaranai, Chennai, Tamil Nadu 600100. <br />
                                                    Phone: 98416 26380 / 78240 46310
                                                </Typography>
                                                <Typography variant="subtitle2" sx={{ fontSize: '0.6rem', color: "black" }}>
                                                    Email: info@govedafoundation.org <br />
                                                    Website: www.govedafoundation.org <br />
                                                    Registration No: 397/2017/BK4
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Box sx={{ my: 2 }}>
                                        <Typography variant="h5" align="center" sx={{ fontWeight: "bold" }}>
                                            Thank You Letter Cum Receipt
                                        </Typography>

                                        <Grid container alignItems="center" sx={{ mt: 2, }}>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle1" align="left" >
                                                    Dear Shri <Box component="span" sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}>{formData.name}</Box>,
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={6} sx={{ textAlign: 'right', fontSize: '0.9rem' }}>
                                                <Typography variant="subtitle1">
                                                    Date: <Box component="span" sx={{ fontWeight: 'bold', }}>{formData.paymentDate}</Box>
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                    </Box>

                                    <Typography variant="subtitle1" sx={{ mt: 2, fontSize: '0.9rem' }}>
                                        Thank you for your valuable contribution towards the cause of <Box component="span" sx={{ fontWeight: 'bold', }}>{formData.supportOption === "Others" ? formData.otherPurposes : formData.supportOption}</Box>. We at GoVeda Foundation are
                                        committed to serving humanity by preserving the age-old knowledge bestowed upon us by our Rishis and
                                        forefathers.
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ mt: 1, fontSize: '0.9rem' }}>
                                        We welcome you into this sathsanga of like-minded people who share their resources for activities that
                                        will help leave a legacy for our future generations.
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ mt: 1, fontSize: '0.9rem' }}>
                                        We also invite you to be volunteers for our various activities as and when required at your convenience.
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ mt: 1, fontSize: '0.9rem' }}>
                                        We look forward to your continued patronage.
                                    </Typography>

                                    <Box sx={{ mt: 3, mb: 3, borderTop: "1px solid grey", p: 3 }} >
                                        <Typography variant="h5" align='center' sx={{ fontWeight: "bold", }}>Receipt</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} >
                                                <Typography variant="subtitle1" sx={{ fontSize: '0.9rem' }}>Receipt No.: {receiptId}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle1" sx={{ fontSize: '0.9rem' }} align="right">Date: <Box component="span" sx={{ fontWeight: 'bold', }}>{formData.paymentDate}</Box></Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Typography variant="subtitle1" sx={{ mt: 2, fontSize: '0.9rem' }}>
                                        Received with thanks from {formData.prefix} <Box component="span" sx={{ fontWeight: 'bold' }}>{formData.name}</Box>,
                                        address: <Box component="span" sx={{ fontWeight: 'bold' }}>{formData.address1}, {formData.address2}, {formData.city_name}, {formData.state_name}, {formData.pincode}</Box>,
                                        the sum of Rupees <Box component="span" sx={{ fontWeight: 'bold', textTransform: "capitalize" }}>{formData.amountinWords}</Box> only towards <Box component="span" sx={{ fontWeight: 'bold' }}>{formData.supportOption === "Others" ? formData.otherPurposes : formData.supportOption}</Box> by
                                        {formData.paymentMode === 'Cheque' && (
                                            <>
                                                {" "}<Box component="span" sx={{ fontWeight: 'bold' }}>{formData.paymentMode}</Box> No.* <Box component="span" sx={{ fontWeight: 'bold' }}>{formData.chequeNumber}</Box>, dated <Box component="span" sx={{ fontWeight: 'bold' }}>{new Date(formData.chequeDate).toLocaleString("en-GB", { dateStyle: "short", timeZone: "UTC" })}</Box>, drawn on <Box component="span" sx={{ fontWeight: 'bold' }}>{formData.govedaBank}</Box>
                                            </>
                                        )}
                                        {formData.paymentMode === 'Bank Transfer' && (
                                            <Box component="span" sx={{ fontWeight: 'bold' }}>{" "}ONLINE</Box>
                                        )}
                                        {formData.paymentMode !== 'Bank Transfer' && formData.paymentMode !== 'Cheque' && (
                                            <Box component="span" sx={{ fontWeight: 'bold' }}>{formData.paymentMode}</Box>
                                        )}
                                        {formData.refNumber && (
                                            <>, Reference No. <Box component="span" sx={{ fontWeight: 'bold' }}>{formData.refNumber}</Box></>
                                        )}.
                                    </Typography>

                                    <Grid container alignItems="center">
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle1" align="left" sx={{ mt: 2, fontSize: '0.9rem' }}>
                                                Rs.<Box component="span" sx={{ fontWeight: 'bold', }}>{formData.amountinNumbers}.00</Box>
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                            <Box >
                                                <img src={require("../../images/PDF Signature/ssurya signature-03.png")} alt="Signature" width={150} height={80} />
                                            </Box>

                                            <Typography variant="subtitle1" sx={{ fontSize: '0.9rem' }}>
                                                For GoVeda Foundation
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Box>



                        </Container>
                    )
                    :
                    (
                        <Grid container spacing={2} sx={{ mb: 10 }}>
                            <Grid item xs={12}>
                                <Typography variant="h6" color='warning'>Kindly, select any one of the above to continue further</Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>

                                <TextField
                                    disabled={true}
                                    fullWidth
                                    label="Name"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    disabled={true}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Phone Number"
                                    disabled={true}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Country"
                                    disabled={true}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="State"
                                    disabled={true}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="City"
                                    disabled={true}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Pincode"
                                    disabled={true}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Address"
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                    )
            }

        </Container >
    );
};

export default withAuth(AdminReceiptCreator);


